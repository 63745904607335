<template>
  <Payment />
</template>
<script>
  import Payment from "@/components/ZillaCards/Payment";
  export default {
    components: {
      Payment,
    },
  };
</script>
