var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
      _c("h1", { staticClass: "text-lg font-bold" }, [_vm._v("Payment")]),
      _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
        _vm._v(" Please choose a payment method to complete your card order "),
      ]),
      _c(
        "Card",
        { staticClass: "mt-4" },
        [
          _c("p", { staticClass: "text-sm text-grey" }, [
            _vm._v("Price for card"),
          ]),
          _c(
            "p",
            { staticClass: "text-lg md:text-xl primary-text font-bold" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.virtualCards.length ? _vm.amount : "Free") +
                  " "
              ),
            ]
          ),
          _vm.card.type === "PHYSICAL"
            ? _c(
                "div",
                { staticClass: "flex items-center justify-between" },
                [
                  _c("Hr", { staticClass: "my-3" }),
                  _c("div", [
                    _c("p", { staticClass: "text-sm text-grey" }, [
                      _vm._v("Delivery address"),
                    ]),
                    _c("p", { staticClass: "text-dark" }, [
                      _vm._v(_vm._s(_vm.fullAddress.line1) + ","),
                    ]),
                    _c("p", { staticClass: "text-dark" }, [
                      _vm._v(_vm._s(_vm.fullAddress.city)),
                    ]),
                    _c("p", { staticClass: "text-dark" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.fullAddress.lga) +
                          ", " +
                          _vm._s(_vm.fullAddress.state) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-brandPurple font-semibold cursor-pointer ml-auto",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/drawer/card/address")
                        },
                      },
                    },
                    [_vm._v(" Edit ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("Hr", { staticClass: "my-3" }),
          _c("p", { staticClass: "text-sm text-grey" }, [
            _vm._v("Payment method"),
          ]),
          _c(
            "div",
            { staticClass: "flex justify-between items-center mt-2" },
            [
              _c("div", [
                _c("p", { staticClass: "text-dark text-lg font-bold" }, [
                  _vm._v("Pay from wallet"),
                ]),
                _vm.wallet.instaPayWallet
                  ? _c("p", { staticClass: "text-sm" }, [
                      _vm._v(
                        " Your balance is " +
                          _vm._s(
                            _vm._f("formatMoney")(
                              _vm.wallet.instaPayWallet.balance
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("p", { staticClass: "text-sm" }, [
                      _vm._v(
                        "Your balance is " + _vm._s(_vm._f("formatMoney")(0))
                      ),
                    ]),
              ]),
              _c("SecondaryButton", {
                attrs: { text: "Fund wallet" },
                on: {
                  click: function ($event) {
                    _vm.showFundAccount = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _vm.virtualCards.length < 1
            ? _c(
                "div",
                { staticClass: "spam-notice mb-2 mt-10 border border-grey-1" },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 20 20",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                          fill: "#0EA2F5",
                        },
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "text-sm text-grey w-full px-3" }, [
                    _vm._v(
                      " Your first virtual card is free. Subsequent cards will attract a payment of "
                    ),
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v(_vm._s(_vm.amount)),
                    ]),
                    _vm._v(". "),
                  ]),
                ]
              )
            : _vm._e(),
          _c("Button", {
            staticClass: "mt-3",
            attrs: {
              width: "w-full",
              loading: _vm.loading,
              text: "Create card",
            },
            on: {
              click: function ($event) {
                return _vm.orderNewCard()
              },
            },
          }),
        ],
        1
      ),
      _c("Modal", { attrs: { display: _vm.showSuccess, hideClose: "" } }, [
        _c("div", { staticClass: "grid place-items-center h-full py-6" }, [
          _c(
            "div",
            [
              _c(
                "svg",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "78",
                    height: "62",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4 34.9701 28.6429 54 73 4",
                      stroke: "#6A30C3",
                      "stroke-width": "11",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M4 34.9701 28.6429 54 73 4",
                      stroke: "url(#a)",
                      "stroke-width": "11",
                    },
                  }),
                  _c(
                    "defs",
                    [
                      _c(
                        "linearGradient",
                        {
                          attrs: {
                            id: "a",
                            x1: "-427.857",
                            y1: "244",
                            x2: "185.813",
                            y2: "510.308",
                            gradientUnits: "userSpaceOnUse",
                          },
                        },
                        [
                          _c("stop", {
                            attrs: {
                              offset: ".38059",
                              "stop-color": "#C6003B",
                            },
                          }),
                          _c("stop", {
                            attrs: {
                              offset: ".49193",
                              "stop-color": "#6A30C3",
                            },
                          }),
                          _c("stop", {
                            attrs: {
                              offset: ".60073",
                              "stop-color": "#63DFDF",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "h1",
                {
                  staticClass:
                    "text-center text-2xl primary-text font-bold mt-4",
                },
                [_vm._v(" Card Created "), _c("br"), _vm._v(" Successfully ")]
              ),
              _c("Button", {
                staticClass: "w-full mt-8",
                attrs: { text: "Select payment plan" },
                on: {
                  click: function ($event) {
                    return _vm.setDefaultPaymentPlan()
                  },
                },
              }),
              _c("Button", {
                staticClass: "w-full mt-3",
                attrs: { outline: "", text: "Skip, I'll do it later" },
                on: {
                  click: function ($event) {
                    _vm.showSuccess = false
                    _vm.$router.push(
                      "/drawer/card/view-card/" + _vm.issuedCardId
                    )
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("AddDebitCard", {
        attrs: { display: _vm.showAddDebitCard },
        on: { close: _vm.handleAddDebitCardClose },
      }),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.showFundAccount,
            title: "Add money to account",
          },
          on: { close: _vm.closeFundWallet },
        },
        [
          _c("FundAccount", {
            on: {
              completed: _vm.completed,
              close: function ($event) {
                _vm.showFundAccount = false
              },
            },
          }),
        ],
        1
      ),
      _c("PaymentPlan", {
        attrs: {
          display: _vm.showPaymentStyle,
          type: _vm.paymentType,
          loading: _vm.selectLoading,
        },
        on: {
          flexiUpdated: _vm.setFlexiConfig,
          close: _vm.closePaymentPlan,
          style: _vm.chooseStyle,
          selected: _vm.planSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }