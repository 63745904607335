<template>
  <div class="main-container">
    <BackNav @click="$router.go(-1)" />
    <h1 class="text-lg font-bold">Payment</h1>
    <p class="mt-2 text-sm text-grey">
      Please choose a payment method to complete your card order
    </p>
    <Card class="mt-4">
      <p class="text-sm text-grey">Price for card</p>
      <p class="text-lg md:text-xl primary-text font-bold">
        {{ virtualCards.length ? amount : "Free" }}
      </p>

      <div v-if="card.type === 'PHYSICAL'" class="flex items-center justify-between">
        <Hr class="my-3" />
        <div>
          <p class="text-sm text-grey">Delivery address</p>
          <p class="text-dark">{{ fullAddress.line1 }},</p>
          <p class="text-dark">{{ fullAddress.city }}</p>
          <p class="text-dark">
            {{ fullAddress.lga }}, {{ fullAddress.state }}
          </p>
        </div>
        <p class="text-brandPurple font-semibold cursor-pointer ml-auto" @click="$router.push('/drawer/card/address')">
          Edit
        </p>
      </div>
      <Hr class="my-3" />
      <p class="text-sm text-grey">Payment method</p>
      <div class="flex justify-between items-center mt-2">
        <div>
          <p class="text-dark text-lg font-bold">Pay from wallet</p>
          <p class="text-sm" v-if="wallet.instaPayWallet">
            Your balance is {{ wallet.instaPayWallet.balance | formatMoney }}
          </p>
          <p class="text-sm" v-else>Your balance is {{ 0 | formatMoney }}</p>
        </div>
        <SecondaryButton text="Fund wallet" @click="showFundAccount = true" />
      </div>
    </Card>

    <div class="mt-10">
      <div v-if="virtualCards.length < 1" class="spam-notice mb-2 mt-10 border border-grey-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#0EA2F5" />
        </svg>
        <p class="text-sm text-grey w-full px-3">
          Your first virtual card is free. Subsequent cards will attract a payment
          of <span class="font-semibold">{{ amount }}</span>.
        </p>
      </div>
      <Button width="w-full" :loading="loading" :text="`Create card`" class="mt-3" @click="orderNewCard()" />
    </div>
    <Modal :display="showSuccess" hideClose>
      <div class="grid place-items-center h-full py-6">
        <div>
          <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="78" height="62" fill="none">
            <path d="M4 34.9701 28.6429 54 73 4" stroke="#6A30C3" stroke-width="11" />
            <path d="M4 34.9701 28.6429 54 73 4" stroke="url(#a)" stroke-width="11" />
            <defs>
              <linearGradient id="a" x1="-427.857" y1="244" x2="185.813" y2="510.308" gradientUnits="userSpaceOnUse">
                <stop offset=".38059" stop-color="#C6003B" />
                <stop offset=".49193" stop-color="#6A30C3" />
                <stop offset=".60073" stop-color="#63DFDF" />
              </linearGradient>
            </defs>
          </svg>
          <h1 class="text-center text-2xl primary-text font-bold mt-4">
            Card Created <br />
            Successfully
          </h1>
          <Button class="w-full mt-8" text="Select payment plan" @click="setDefaultPaymentPlan()" />
          <Button class="w-full mt-3" outline text="Skip, I'll do it later" @click="
            showSuccess = false;
            $router.push(`/drawer/card/view-card/${issuedCardId}`);
          " />
        </div>
      </div>
    </Modal>
    <!-- to add debit cards -->
    <AddDebitCard :display="showAddDebitCard" @close="handleAddDebitCardClose" />
    <Modal :display="showFundAccount" @close="closeFundWallet" title="Add money to account">
      <FundAccount @completed="completed" @close="showFundAccount = false" />
    </Modal>
    <PaymentPlan @flexiUpdated="setFlexiConfig" :display="showPaymentStyle" @close="closePaymentPlan" @style="chooseStyle"
      @selected="planSelected" :type="paymentType" :loading="selectLoading" />
  </div>
</template>
<script>
import { BackNav } from "@/UI/Navigation";
import { SecondaryButton, Button } from "@/UI/Button";
import { LargeModal, Modal } from "@/UI/Modals";
import PaymentPlan from "./PaymentPlan";
import { mapActions, mapState } from "vuex";

// flexiblePayment, config imported from zillaCard mixin
import zillaCard from "@/mixins/zillaCard";
import Hr from "@/UI/Hr";
export default {
  mixins: [zillaCard],
  components: {
    BackNav,
    Hr,
    SecondaryButton,
    Button,
    LargeModal,
    Modal,
    PaymentPlan,
    FundAccount: () => import("../Home/FundAccount"),
    AddDebitCard: () => import("./AddDebitCard.vue"),
  },
  data: () => ({
    showSuccess: false,
    showFundAccount: false,
    loading: false,
    showPaymentStyle: false,
    paymentType: {},
    selectLoading: false,
    issuedCardId: "",
    amount: "₦1,000",
    params: {
      pageSize: 20,
      page: 0,
      sortOrder: "DESC",
      from: "",
      to: "",
      type: "VIRTUAL",
    },
    showAddDebitCard: false,
    config: {
      numberOfRepayment: 1,
      upfrontAmountPercentage: 25,
      // repaymentStartDate: ""
    }
  }),
  computed: {
    ...mapState({
      wallet: (state) => state?.dashboard?.userWallet,
      fullAddress: (state) => state?.card?.fullAddress,
      card: (state) => state?.card?.card,
      virtualCards: (state) => state?.card?.virtualCards,
    }),
  },
  mounted() {
    this.getUserWallet();
    this.getVirtualCards();
  },
  methods: {
    ...mapActions("card", [
      "getAllVirtualCards",
      "orderCard",
      "updateCardDefaultPaymentPlan",
    ]),
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("dashboard", ["getUserWallet"]),
    ...mapActions("api", ["setErrorCodeToWatch"]),
    orderNewCard() {
      this.loading = true;
      if (this.card.type === "PHYSICAL") {
        this.orderPhysicalCard();
      } else if (this.card.type === "VIRTUAL") {
        this.orderVirtualCard();
      }
    },
    getVirtualCards(type) {
      this.getAllVirtualCards({
        params: this.params,
        type: type,
      })
        .then((res) => { })
        .catch(() => { });
    },

    orderVirtualCard() {
      this.orderCard({
        type: this.card.type,
        currency: this.card.currency,
        nickname: this.card.nickname,
        pin: this.card.pin,
        color: this.card.color,
      })
        .then((res) => {
          this.issuedCardId = res.data.data.id;
          this.showSuccess = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    orderPhysicalCard() {
      this.orderCard({
        type: this.card.type,
        currency: this.type.currency,
        nickname: this.card.nicknam,
        deliveryAddressId: this.card.deliveryAddressId,
        color: this.card.color,
      })
        .then((res) => {
          this.issuedCardId = res.data.data.id;
          this.showSuccess = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    completed() {
      this.showFundAccount = false;
      this.showAlert({
        display: true,
        type: "success",
        description: `Card successfully added`,
      });
    },

    closeFundWallet() {
      this.getUserWallet();
      this.showFundAccount = false;
    },

    setDefaultPaymentPlan() {
      this.showSuccess = false;
      this.showPaymentStyle = true;
    },
    chooseStyle(e) {
      this.paymentType = e;
    },

    planSelected() {
      if (this.checkIfDefaultPlan(this.paymentType.id)) {
        this.setCardDefaultPaymentPlan();
      }
      else {
        this.setFlexiblePlan();
      }
    },

    setCardDefaultPaymentPlan() {
      this.selectLoading = true;
      this.setErrorCodeToWatch(412);
      this.updateCardDefaultPaymentPlan({
        issuedCardId: this.issuedCardId,
        paymentPlanId: this.paymentType.id,
      })
        .then((res) => {
          this.showPaymentStyle = false;
          this.selectLoading = true;
          let style = res?.data?.data?.paymentPlan.name;
          this.showAlert({
            display: true,
            type: "green-success",
            title: "Payment plan set successfully ",
            description: `Your default payment plan for this card has been set to  "${style}" payment.`,
            buttonText: "Okay"
          });
          this.$router.push(`/drawer/card/view-card/${this.issuedCardId}`);
        })
        .catch((err) => {
          this.showSuccess = true;
          this.selectLoading = false;
          if (err.response.status === 412) {
            this.showAddDebitCard = true;
            this.showPaymentStyle = false;
          }
        });
    },


    setFlexiblePlan() {
      this.config.upfrontAmountPercentage = Number(this.config.upfrontAmountPercentage);
      this.selectLoading = true;
      this.setErrorCodeToWatch(412);
      this.updateCardDefaultPaymentPlan({
        issuedCardId: this.issuedCardId,
        flexiblePaymentPlanConfig: this.config
      })
        .then((res) => {
          this.showPaymentStyle = false;
          this.selectLoading = true;
          let style = res?.data?.data?.paymentPlan.name;
          this.showAlert({
            display: true,
            type: "green-success",
            title: "Payment plan set successfully ",
            description: `Your default payment plan for this card has been set to  "${style}" payment.`,
            buttonText: "Okay"
          });
          this.$router.push(`/drawer/card/view-card/${this.issuedCardId}`);
        })
        .catch((err) => {
          this.showSuccess = true;
          this.selectLoading = false;
          if (err.response.status === 412) {
            this.showAddDebitCard = true;
            this.showPaymentStyle = false;
          }
        });
    },

    handleAddDebitCardClose() {
      this.showAddDebitCard = false;
      this.showPaymentStyle = true;
    },
    closePaymentPlan() {
      this.showPaymentStyle = false;
      this.$router.push("/drawer/card");
    },

    setFlexiConfig(config) {
      this.config = config;
      //  this.config.repaymentStartDate = '2023-05-10T19:31:15.304443';
    }
  },
};
</script>

<style scoped>
.main-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>
